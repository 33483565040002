import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import {fetchCreateDoctor, fetchSpecializations} from "../../../actions";

const CreateDoctor = props =>  {

    const {lastdoctor, fetchCreateDoctor, fetchSpecializations, specializations} = props;

    useEffect(() => {
        fetchSpecializations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [category, setCategory] = useState(specializations.length > 0 ? specializations[0].label : '');

    useEffect(() => {
        setCategory(specializations.length > 0 ? specializations[0].label : '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specializations]);

    const createDoctor = () => {
        fetchCreateDoctor({
            name: name,
            contact: contact,
            email: email,
            specialization: category
        })
    }

    return (
        <div className="tile">
            <h5>Создать аккаунт специалиста</h5>
            <div className="mt-15">
                <div>Имя специалиста:</div>
                <div className="wp-100 mt-5">
                    <input type="text" className="wp-100" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
            </div>
            <div className="mt-15">
                <div>Email специалиста:</div>
                <div className="wp-100 mt-5">
                    <input type="text" className="wp-100" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
            </div>
            <div className="mt-15">
                <div>Другой контакт специалиста:</div>
                <div className="wp-100 mt-5">
                    <input type="text" className="wp-100" value={contact} onChange={(e) => setContact(e.target.value)} />
                </div>
            </div>
            <div className="mt-15">
                <div className="w-150">Специализация:</div>
                <div className="wp-100 mt-5">
                    <select className="wp-100" value={category} onChange={(e) => setCategory(e.target.value)} >
                        {specializations.length > 0 &&
                            specializations.map((item, index) => <option className="w-200" key={index} value={item.label}>{item.label}</option>)
                        }
                    </select>
                </div>
            </div>
            <div className="mt-20">
                <button onClick={createDoctor}>Создать специалиста</button>
            </div>
            <div className="mt-20">
                {(lastdoctor && lastdoctor.doctorId) &&
                <div>
                    <strong>Специалист создан</strong>
                    <p className="mt-10"><strong>Имя пользователя:</strong></p>
                    <p className="mt-5">{lastdoctor.login}</p>
                    <p className="mt-10"><strong>Пароль:</strong></p>
                    <p className="mt-5">{lastdoctor.password}</p>
                    <p className="mt-10"><strong>ID специалиста:</strong></p>
                    <p className="mt-5">{lastdoctor.doctorId}</p>

                </div>
                }
            </div>

        </div>
    );
}

const mapStateToProps = ({  data: { lastdoctor, specializations }}) => {
    return { lastdoctor, specializations }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCreateDoctor: fetchCreateDoctor(dispatch),
        fetchSpecializations: fetchSpecializations(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDoctor);
