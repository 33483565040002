import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {fetchDoctors, fetchUpdateDoctor, fetchDeleteDoctor, fetchOrganizations} from "../../../actions";
import EditDoctor from "./edit-doctor";

const EditDoctors = props =>  {

    useEffect(() => {

        props.fetchDoctors();
        props.fetchOrganizations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [search, setSearch] = useState('');

    const {doctors} = props;

    const deleteOrganizationHandler = (id) => {
        props.fetchDeleteDoctor(id)
    }

    const saveDoctorHandler = (id, obj) => {
        props.fetchUpdateDoctor(id, obj)
    }

    const filteredDoctors = search.length < 3 ? doctors : doctors.filter(item => item.specialization.toLowerCase().indexOf(search.toLowerCase()) > -1)

    return (
        <div className="tile wp-100">
            <h5>Управление специалистами</h5>
            <div className="mt-20 d-flex-ac">
                <input placeholder="Поиск по специализации" type="text" className="wp-100" value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
            {
                <div className="mt-20">
                    <p>Найденные специалисты:</p>
                    {filteredDoctors.length > 0 &&
                        filteredDoctors.map((item, index) =>
                            <EditDoctor
                                key={index}
                                item={item}
                                callbackDelete={(id) => deleteOrganizationHandler(id)}
                                callbackEdit={(id, obj) => saveDoctorHandler(id, obj)}
                            />)
                    }
                </div>
            }


        </div>
    );
}

const mapStateToProps = ({  data: { doctors }}) => {
    return { doctors }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDoctors: fetchDoctors(dispatch),
        fetchOrganizations: fetchOrganizations(dispatch),
        fetchUpdateDoctor: fetchUpdateDoctor(dispatch),
        fetchDeleteDoctor: fetchDeleteDoctor(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDoctors);
