import React, {useState} from "react";
import { connect } from 'react-redux';
import { fetchUser } from '../../actions';

import "./main.css";
import "./shortcuts.css";
import "./forms.css";
import LoginScreen from "../login-screen/login-screen";
import CreateDoctor from "../modules/CreateDoctor/create-doctor";
import EditDoctors from "../modules/EditDoctors/edit-doctors";
import EditSpecializations from "../modules/EditSpecializations/edit-specializations";
import Statistics from "../modules/Statistics";

const App = props =>  {

    const { token } = props;
    const [page, setPage] = useState(0);

    return (
        <div className="wrapper">
            <div className="container">
                <div className="mt-30">
                    <h1>{process.env.REACT_APP_THEME === 'legal' ? 'Legal Hub' : 'eЗдоровье'}</h1>
                    <h4>Администрирование</h4>
                    <div className="mt-30">
                        {token === "" &&
                            <LoginScreen />
                        }
                        {token !== "" &&
                            <div>
                                <div>
                                    <select className="w-250" value={page} onChange={(e) => setPage(e.target.selectedIndex)}>
                                        <option value={0}>Создание специалистов</option>
                                        <option value={1}>Управление специалистами</option>
                                        <option value={2}>Управление специализациями</option>
                                        <option value={3}>Статистика</option>
                                    </select>
                                </div>
                                <div className="mt-20">
                                    {page === 0 && <CreateDoctor />}
                                    {page === 1 && <EditDoctors />}
                                    {page === 2 && <EditSpecializations />}
                                    {page === 3 && <Statistics />}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { token, userId }}) => {
  return { token, userId }
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchUser: fetchUser(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
