import React, { useState } from "react";
import { connect } from 'react-redux';
import { fetchUser } from '../../actions';


const LoginScreen = props =>  {

    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');

    return (
        <div>
            <p className="mb-5">Имя пользователя:</p>
            <input type="text" value={login} onChange={(e) => setLogin(e.target.value)} />
            <p className="mt-15 mb-5">Пароль</p>
            <input type="password" value={pass} onChange={(e) => setPass(e.target.value)} />
            <div className="mt-20">
                <button onClick={() => props.fetchUser(login, pass)}>Войти</button>
            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { token }}) => {
    return { token }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: fetchUser(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
