
import store from "../../store";

const SERVER_URL = process.env.REACT_APP_API_URL ?
    process.env.REACT_APP_API_URL : "";

export default class APIService {

  getAsyncFetch = (urladdon, requestOptions={}, serverToLoad= SERVER_URL) => {

    console.log("requesting data from: " + (serverToLoad + urladdon));

    return fetch(serverToLoad + urladdon, requestOptions)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            return res.json()
          } else {
            return Promise.reject(res.statusText);
          }
        })
        .then(res => {
          if(res === undefined) {
            return Promise.reject("Произошла ошибка загрузки данных. Мы обязательно исправим ее в ближайшее время");
          }
          return Promise.resolve(res.result || "")
        })
        .catch(error => {
          return Promise.reject(error);
        })
  }

  getUserData = async (login, pass) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "login": login,
        "password": pass
      })
    };

    return this.getAsyncFetch("/auth/login", requestOptions);
  };

  getAllDoctors = async () => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch("/doctor", requestOptions);
  };

  getAllSpecializations = async () => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch("/specialization/all", requestOptions);
  };

  getOrganizations = async () => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch("/organization", requestOptions);
  };

  updateSpecialization = async (id, obj) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };

    return this.getAsyncFetch("/specialization/" + id, requestOptions);
  };

  createSpecialization = async (obj) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };

    return this.getAsyncFetch("/specialization/", requestOptions);
  };

  deleteSpecialization = async (id) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch("/specialization/" + id, requestOptions);
  };

  updateDoctor = async (id, obj) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };

    return this.getAsyncFetch("/doctor/" + id, requestOptions);
  };

  deleteDoctor = async (id) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch("/doctor/" + id, requestOptions);
  };


  createDoctor = async (obj) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };

    return this.getAsyncFetch("/doctor", requestOptions);
  };

  createUser = async (obj) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };

    return this.getAsyncFetch("/auth/registration", requestOptions);
  };

}
