
export const toTwoDigits = (num) => num < 10 ? "0" + num.toString() : num.toString()

export const getTextDate = (dateToExtract, isShort=false, isSmall=false) => {
    const date = new Date(dateToExtract)
    let months = ["ЯНВАРЯ", "ФЕВРАЛЯ", "МАРТА", "АПРЕЛЯ", "МАЯ", "ИЮНЯ", "ИЮЛЯ", "АВГУСТА", "СЕНТЯБРЯ", "ОКТЯБРЯ", "НОЯБРЯ", "ДЕКАБРЯ"]
    const monthsBEL = ["СТУДЗЕНЯ", "ЛЮТАГА", "САКАВIКА", "КРАСАВIКА", "МАЯ", "ЧЭРВЕНЯ", "ЛIПЕНЯ", "ЖНИЎНЯ", "ВЕРАСНЯ", "КАСТРЫЧНIКА", "ЛIСТАПАДА", "СЬНЕЖНЯ"]

    if (localStorage.getItem('language') === 'be') months = monthsBEL;

    let monthLabel = isShort ? months[date.getMonth()].substring(0, 3) : months[date.getMonth()];
    if (isSmall) monthLabel = monthLabel.toLowerCase();
    return `${date.getDate()} ${monthLabel}`
}

export const getTextDateNums = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${date.getDate()}.${toTwoDigits(date.getMonth() + 1)}`
}

export const toDateTimeString = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${toTwoDigits(date.getDate())}.${toTwoDigits(date.getMonth() + 1)}T${toTwoDigits(date.getHours())}:${toTwoDigits(date.getMinutes())}`;
}

export const toBrowserDate = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${date.getFullYear()}-${toTwoDigits(date.getMonth() + 1)}-${toTwoDigits(date.getDate())}`;
}

export const getTextTime = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${toTwoDigits(date.getHours())}:${toTwoDigits(date.getMinutes())}`
}

export const isToday = (dateToExtract) => {
    const date = new Date(dateToExtract)
    const today = new Date();
    return (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear())
}

export const browserDateToStringDate = (browserDate, isSmall= true) => {
    const date = new Date(browserDate);
    return getTextDate(date, false, isSmall)
}

export const dateToStringDate = (data, isSmall= true, isShort=false) => {
    const date = new Date(data);
    return getTextDate(date, isShort, isSmall)
}

export const dateToStringDateTime = (data, isSmall= true, isShort=false) => {
    const date = new Date(data);
    return `${getTextDate(date, isShort, isSmall)}, ${getTextTime(date)}`
}

export const isTextInArrayItems = (items=[], text='') => {
    if (!items || !text || items.length === 0) return false;
    let result = false;
    items.forEach(item => {
        if (item && Array.isArray(item)) {
            item.forEach(subItem => {
                if (subItem.text && subItem.text.toLowerCase().indexOf(text.toLowerCase()) > -1) result = true
            })
        } else {
            if (item && item.toLowerCase().indexOf(text.toLowerCase()) > -1) result = true;
        }
    })
    return result
}

export const buildTimeSlots = (slots) => {

    let date = new Date()
    date.setHours(23);
    date.setMinutes(30);

    const result = []
    for (let i=0; i<48; i++ ) {

        date.setMinutes(date.getMinutes() + 30);

        const slot = slots.find(item => getTextTime(new Date(item.time)) === getTextTime(date))

        const dateToAdd = {
            time: getTextTime(date),
            origin: new Date(date),
            selected: slot !== undefined,
            busy: false
        }
        if (slot && slot.appointmentId && slot.appointmentId !== "") {
            dateToAdd.appointmentId = slot.appointmentId;
            dateToAdd.busy = true;
        }

        result.push(dateToAdd)
    }

    return result
}
