const updateData = (state, action) => {

    if (state === undefined) {
        return {
            token: "",
            userId: "",

            doctors: [],
            specializations: [],
            organizations: [],
            lastdoctor: {}
        };
    }

    switch (action.type) {

        case 'FETCH_USER_DATA_SUCCESS':
            return {
                ...state.data,
                token: action.payload.token,
                userId: action.payload.userId
            };

        case 'FETCH_DOCTOR_CREATED': {
            return {
                ...state.data,
                lastdoctor: action.payload,
            }
        }

        case 'FETCH_ORGANIZATIONS_LOADED': {
            return {
                ...state.data,
                organizations: action.payload,
            }
        }

        case 'FETCH_DOCTORS_LOADED': {
            return {
                ...state.data,
                doctors: action.payload,
            }
        }

        case 'FETCH_SPECIALIZATIONS_LOADED': {
            return {
                ...state.data,
                specializations: action.payload,
            }
        }


        default:
            return state.data;
    }
};

export default updateData;
