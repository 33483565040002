import APIService from "../services/api-service";


const doctorCreated = (data) => {
    return {
        type: 'FETCH_DOCTOR_CREATED',
        payload: data
    };
};

const doctorsLoaded = (data) => {
    return {
        type: 'FETCH_DOCTORS_LOADED',
        payload: data
    };
};

const specializationsLoaded = (data) => {
    return {
        type: 'FETCH_SPECIALIZATIONS_LOADED',
        payload: data
    };
};

const organizationsLoaded = (data) => {
    return {
        type: 'FETCH_ORGANIZATIONS_LOADED',
        payload: data
    };
};


const dataUserLoaded = (data) => {
    return {
        type: 'FETCH_USER_DATA_SUCCESS',
        payload: data
    };
};

export const fetchUser = (dispatch) => (login, pass) => {
    const userService = new APIService();

    userService.getUserData(login, pass)
        .then((data) => dispatch(dataUserLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchDoctors = (dispatch) => () => {
    const userService = new APIService();

    userService.getAllDoctors()
        .then((data) => dispatch(doctorsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchSpecializations = (dispatch) => () => {
    const userService = new APIService();

    userService.getAllSpecializations()
        .then((data) => dispatch(specializationsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchOrganizations = (dispatch) => () => {
    const userService = new APIService();

    userService.getOrganizations()
        .then((data) => dispatch(organizationsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchCreateSpecialization = (dispatch) => (obj) => {
    const userService = new APIService();

    userService.createSpecialization(obj)
        .then((data) => {
            window.alert("Специализация создана успешно");
            dispatch(specializationsLoaded(data))
        })
        .catch((err) => console.log(err));
};

export const fetchUpdateSpecialization = (dispatch) => (id, obj) => {
    const userService = new APIService();

    userService.updateSpecialization(id, obj)
        .then((data) => {
            window.alert("Специализация обновлена успешно");
            dispatch(specializationsLoaded(data))
        })
        .catch((err) => console.log(err));
};

export const fetchDeleteSpecialization = (dispatch) => (id) => {
    const userService = new APIService();

    userService.deleteSpecialization(id)
        .then((data) => {
            window.alert("Специализация удалена успешно");
            dispatch(specializationsLoaded(data))
        })
        .catch((err) => console.log(err));
};

export const fetchUpdateDoctor = (dispatch) => (id, obj) => {
    const userService = new APIService();

    userService.updateDoctor(id, obj)
        .then((data) => {
            window.alert("Специалист обновлен успешно");
            fetchDoctors(dispatch)();
        })
        .catch((err) => console.log(err));
};

export const fetchDeleteDoctor = (dispatch) => (id) => {
    const userService = new APIService();

    userService.deleteDoctor(id)
        .then((data) => {
            window.alert("Специалист удален успешно");
            fetchDoctors(dispatch)();
        })
        .catch((err) => console.log(err));
};

export const fetchCreateDoctor = (dispatch) => (obj) => {
    const userService = new APIService();

    const newUser = {
        role: "common-doctor",
        name: obj.name || "",
        contact: obj.contact || "",
        email: obj.email || "",
        login: "" + new Date().getTime().toString().slice(0, -3),
        password: Math.random().toString(36).substr(2, 8)
    }

    userService.createUser(newUser)
        .then((data) => {

            if (!data.userId) {
                console.log(data)
                return;
            }

            const newDoctor = {
                specialization: obj.specialization,
                userId: data.userId
            }

            userService.createDoctor(newDoctor)
                .then((doctor) => {
                    if (doctor.doctorId) {
                        dispatch(doctorCreated({...newUser, specialization: obj.specialization, doctorId: doctor.doctorId}))
                        window.alert("Специалист создан успешно");
                        fetchDoctors(dispatch)()
                    } else {
                        window.alert("Ошибка создания специалиста")
                    }

                })
                .catch((err) => console.log(err));

        })
        .catch((err) => console.log(err));
};

