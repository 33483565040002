import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Multiselect from 'multiselect-react-dropdown';
import CheckBox from "../../controls/checkbox";

const EditDoctor = props =>  {

    const {item, callbackDelete, callbackEdit, specializations, organizations} = props;

    const [doctorId, setId] = useState(item.doctorId);
    const [name, setName] = useState(item.doctorId.name);
    const [contact, setContact] = useState(item.doctorId.contact);
    const [email, setEmail] = useState(item.doctorId.email);
    const [isBlocked, setIsBlocked] = useState(item.doctorId.isBlocked);
    const [specialization, setSpecialization] = useState(item.specialization);
    const [description, setDescription] = useState(item.description);
    const [secondarySpecializations, setSecondarySpecializations] = useState(item.secondarySpecializations);
    const [organizationsSelected, setOrganizationsSelected] = useState(item.organizations || []);

    useEffect(() => {
        setId(item.doctorId);
        setName(item.doctorId.name);
        setContact(item.doctorId.contact);
        setEmail(item.doctorId.email);
        setSpecialization(item.specialization);
        setDescription(item.description);
        setIsBlocked(item.doctorId.isBlocked);
        setOrganizationsSelected(item.organizations || []);
        setSecondarySpecializations(item.secondarySpecializations)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const sendDeleteCallback = () => {
        callbackDelete(doctorId._id)
    }

    const sendEditCallback = () => {
        callbackEdit(doctorId._id, {
            name: name,
            description: description,
            specialization: specialization,
            organizations: organizationsSelected,
            isBlocked: isBlocked,
            secondarySpecializations: secondarySpecializations,
            email: email,
            contact: contact
        })
    }

    const updateSecondarySpecializations = (list) => {
        const listToUpdate = list.map(item => item.name);
        setSecondarySpecializations(listToUpdate)
    }

    const secondaryOptions = specializations.map((item, index) => {return {name: item.label, id: index}})
    const secondaryOptionsSelected = secondarySpecializations.map((item, index) => {return {name: item, id: index}})

    const updateOrganizations = (list) => {
        const listToUpdate = list.map(item => item._id);
        console.log(listToUpdate)
        setOrganizationsSelected(listToUpdate)
    }

    if (!organizations || organizations.length === 0) return null

    const organizationsOptions = organizations.map((item, index) => {return {label: item.label, _id: item._id, id: index}})

    const organizationsSelectedList = organizationsSelected.map((item, index) => {return {label: organizations.find(el => el._id === item)?.label, _id: item, id: index}})

    return (
        <div>
            <div className="d-flex-wrap-ac wp-100 mt-20">
                <h5 className='mb-20 wp-100'>Cпециалист</h5>
                <div className="mb-20 mr-15 wmobile">
                    <p className="fs-12">id: <strong>{doctorId._id}</strong></p>
                    <p className="fs-12">login: <strong>{doctorId.login}</strong></p>
                    <div className="mt-5">
                        <select className="wp-100 pr-30" value={specialization} onChange={(e) => setSpecialization(e.target.value)} >
                            {specializations.length > 0 &&
                                specializations.map((item, index) => <option key={index} value={item.label}>{item.label}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Доп специализации:</strong></p>
                    <div className="mt-5">
                        <Multiselect
                            options={secondaryOptions}
                            selectedValues={secondaryOptionsSelected}
                            onSelect={(selectedList) => updateSecondarySpecializations(selectedList)}
                            onRemove={(selectedList) => updateSecondarySpecializations(selectedList)}
                            displayValue="name" // Property name to display in the dropdown options
                        />
                    </div>
                </div>
                <div className="mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Организации:</strong></p>
                    <div className="mt-5">
                        <Multiselect
                            options={organizationsOptions}
                            selectedValues={organizationsSelectedList}
                            onSelect={(selectedList) => updateOrganizations(selectedList)}
                            onRemove={(selectedList) => updateOrganizations(selectedList)}
                            displayValue="label" // Property name to display in the dropdown options
                        />
                    </div>
                </div>
                <div className="w-150 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Имя:</strong></p>
                    <div className="mt-5">
                        <input className="wp-100" value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>
                </div>
                <div className="w-200 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Описание:</strong></p>
                    <div className="mt-5">
                        <input className="wp-100" value={description} onChange={(e) => setDescription(e.target.value)}/>
                    </div>
                </div>
                <div className="w-150 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Email:</strong></p>
                    <div className="mt-5">
                        <input className="wp-100" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                </div>
                <div className="w-150 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Другой контакт:</strong></p>
                    <div className="mt-5">
                        <input className="wp-100" value={contact} onChange={(e) => setContact(e.target.value)}/>
                    </div>
                </div>
                <div className="w-150 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Заблокировать:</strong></p>
                    <div className="mt-5">
                        <CheckBox value={isBlocked} onChange={(val) => setIsBlocked(val)} />
                    </div>
                </div>

                <div className="mb-20">
                    <p className="fs-12"><strong>Действия:</strong></p>
                    <div className="mt-5">
                        <button className="button-link" onClick={() => window.confirm("Вы точно хотите удалить этого специалиста?") &&
                            sendDeleteCallback()
                        }>Удалить</button>
                        <button onClick={() => window.confirm("Вы точно хотите обновить этого специалиста?") &&
                            sendEditCallback()
                        }>Обновить</button>
                    </div>
                </div>
            </div>
            <div className="separator wp-100" />
        </div>
    );
}

const mapStateToProps = ({  data: { specializations, organizations }}) => {
    return { specializations, organizations }
};

export default connect(mapStateToProps)(EditDoctor);
