import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {fetchSpecializations, fetchUpdateSpecialization, fetchDeleteSpecialization, fetchCreateSpecialization} from "../../../actions";
import EditSpecialization from "./edit-specialization"

const EditSpecializations = props =>  {

    useEffect(() => {

        props.fetchSpecializations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //const [search, setSearch] = useState('');
    const [label, setLabel] = useState('');

    const { specializations } = props;

    const deleteHandler = (id) => {
        props.fetchDeleteSpecialization(id)
    }

    const saveHandler = (id, obj) => {
        props.fetchUpdateSpecialization(id, obj)
    }

    const createHandler = (id, obj) => {
        props.fetchCreateSpecialization({
            label: label.toLowerCase(),
            type: "primary"
        })
    }

    return (
        <div className="tile wp-100">
            <h5>Управление специализациями</h5>

            <div className="mt-20">
                <p>Создание специализации</p>
                <div className="mt-10">
                    <input className="w-200" value={label} onChange={(e) => setLabel(e.target.value)}/>
                    <button onClick={createHandler}>Создать</button>
                </div>
            </div>
            <div className="separator mt-10 mb-10" />

            <div className="mt-20">
                {specializations.length > 0 &&
                    specializations.map((item, index) =>
                        <EditSpecialization
                            key={index}
                            item={item}
                            callbackDelete={(id) => deleteHandler(id)}
                            callbackEdit={(id, obj) => saveHandler(id, obj)}
                        />)
                }
            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { specializations }}) => {
    return { specializations }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSpecializations: fetchSpecializations(dispatch),
        fetchUpdateSpecialization: fetchUpdateSpecialization(dispatch),
        fetchDeleteSpecialization: fetchDeleteSpecialization(dispatch),
        fetchCreateSpecialization: fetchCreateSpecialization(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSpecializations);
